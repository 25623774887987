export const nl = {
    PANUP: "Omhoog bewegen",
    PANDOWN: "Omlaag bewegen",
    PANLEFT: "Naar links bewegen",
    PANRIGHT: "Naar rechts bewegen",
  
    ZOOMIN: "Inzoomen",
    ZOOMOUT: "Uitzoomen",
  
    TOGGLEZOOM: "Zoomniveau wisselen",
    TOGGLE1TO1: "Zoomniveau wisselen",
    ITERATEZOOM: "Zoomniveau wisselen",
  
    ROTATECCW: "Linksom draaien",
    ROTATECW: "Rechtsom draaien",
  
    FLIPX: "Horizontaal omkeren",
    FLIPY: "Verticaal omkeren",
  
    FITX: "Horizontaal passend maken",
    FITY: "Verticaal passend maken",
  
    RESET: "Reset",
  
    TOGGLEFS: "Toggle fullscreen",
  };